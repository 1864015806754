const PrivacyPolice = () => {

    return (
        <div className="privacy">
            Кинотеатр NurCinema стремится обеспечить защиту вашей конфиденциальности. Настоящая Политика
            конфиденциальности объясняет, какие данные мы собираем, как мы их используем и защищаем при использовании
            приложения NurCinema для онлайн-заказа билетов в кинотеатр.
            <br/><br/>
            1. Какие данные мы собираем<br/>
            Личные данные:<br/>
            • Имя и фамилия<br/>
            • Номер телефона<br/>
            • Адрес электронной почты<br/>
            • Платежные данные (при покупке билетов, через защищенные платежные шлюзы)
            <br/><br/>
            Данные использования:<br/>
            • История заказов билетов<br/>
            • Предпочтения фильмов и выбранные залы
            <br/><br/>
            2. Как мы используем ваши данные<br/>
            Мы собираем ваши данные для следующих целей:<br/>
            • Оформление и управление вашими заказами<br/>
            • Улучшение функциональности и качества приложения<br/>
            • Персонализация предложений и рекомендаций
            <br/><br/>
            3. Передача данных третьим лицам<br/>
            Мы можем передавать ваши данные следующим сторонам:<br/>
            • Платежные системы для обработки транзакций<br/>
            • Партнеры (например, кинотеатры) для выполнения вашего заказа<br/>
            • Компании, предоставляющие аналитические услуги (анонимные данные)<br/>
            Мы гарантируем, что ваши данные никогда не будут проданы или переданы третьим лицам без вашего согласия.
            <br/><br/>
            4. Защита данных<br/>
            Мы принимаем все необходимые меры для защиты ваших данных от утраты, несанкционированного доступа, изменения
            или раскрытия:<br/>
            • Шифрование платежных данных<br/>
            • Регулярное обновление системы безопасности<br/>
            • Ограниченный доступ к вашим данным внутри компании
            <br/><br/>
            5. Ваши права<br/>
            Вы имеете право:<br/>
            • Узнать, какие данные мы собираем и как их используем<br/>
            • Изменить или удалить ваши данные<br/>
            • Подать жалобу в надзорные органы в случае нарушения ваших прав<br/>
            Для реализации этих прав свяжитесь с нами по телефону: +996 (551) 56 64 44.
            <br/><br/>
            6. Обновления политики<br/>
            Мы оставляем за собой право обновлять настоящую Политику конфиденциальности. Все изменения будут
            опубликованы в приложении, и мы уведомим вас, если они затронут ваши права.
            <br/><br/>
            7. Контакты<br/>
            Если у вас есть вопросы или жалобы по поводу обработки ваших данных, пожалуйста, свяжитесь с нами:<br/>
            • Телефон: +996 (551) 56 64 44
            <br/><br/>
            Эта Политика конфиденциальности разработана для обеспечения прозрачности в отношении ваших данных. Используя
            приложение NurCinema, вы соглашаетесь с условиями этой Политики конфиденциальности.
            <br/><br/>
        </div>
    )
}
export default PrivacyPolice;